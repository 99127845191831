<template>
  <transition name="toggle">
    <div
      v-show="value"
      class="dialog-mask">
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step
            :complete="e1 > 1"
            step="1"
          >开始/结束日期</v-stepper-step
          >
          <v-divider />
          <v-stepper-step
            :complete="e1 > 2"
            step="2">选择素材</v-stepper-step>
          <v-divider />
          <v-stepper-step step="3">选择设备组或设备</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card-title>
              <v-flex
                xs12
                md6>
                <v-text-field
                  v-model="advertname"
                  :rules="[rules.required, rules.counter]"
                  label="广告名称"
                  prepend-icon="mdi-television"
                  counter
                  maxlength="20"
                />
                <material-datepicker
                  ref="childDatePicker"
                  v-model="rangeAdd"
                  :min="today"
                  :range="true"
                  always-show-panel
                  language="zh-cn"
                  start-label="开始日期"
                  end-label="结束日期"
                  separator-label="/"
                  class="picker picker-range"
                  max="2030-01-01"
                  @input="dateAddInput"
                />
              </v-flex>
            </v-card-title>
            <v-card
              class="mb-5"
              color="#eeeeee"
              height="330px" />
            <v-btn
              color="primary"
              @click="selectFile">
              选择素材
            </v-btn>
            <v-btn
              color="primary"
              flat
              style="margin-left: 40px"
              @click="cancel"
            >
              取消</v-btn
              >
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card-title>
              <v-flex
                xs12
                md3>
                <v-text-field
                  v-model="fileValue"
                  placeholder="已选择的素材"
                  label="已选择的素材"
                  class="v-date-range__input-field"
                  readonly
                  single-line
                  hide-details
                />
              </v-flex>
              <v-flex
                xs12
                md6 />
              <v-flex
                xs12
                md3>
                <v-text-field
                  v-model="filesearch"
                  append-icon="mdi-magnify"
                  label="智能模糊搜索"
                  single-line
                  hide-details
                />
              </v-flex>
            </v-card-title>
            <v-card
              class="mb-5"
              color="#eeeeee">
              <v-data-table
                v-model="fileselected"
                :headers="fileheaders"
                :items="fileitems"
                :search="filesearch"
                :pagination.sync="filepagination"
                :expand="true"
                :rows-per-page-items="[filepagination.rowsPerPage]"
                class="elevation-3 "
              >
                <template v-slot:items="rowsprops">
                  <tr>
                    <td>
                      <v-checkbox
                        v-model="rowsprops.selected"
                        color="#42a5f5"
                        hide-details
                        @click="singleFile(rowsprops)"
                      />
                    </td>
                    <td @click="showFileDetail(rowsprops)">
                      {{ rowsprops.item.index }}
                    </td>
                    <td @click="showFileDetail(rowsprops)">
                      {{ rowsprops.item.srcname }}
                    </td>
                    <td @click="showFileDetail(rowsprops)">
                      {{ rowsprops.item.filesize }}
                    </td>
                    <td @click="showFileDetail(rowsprops)">
                      {{ rowsprops.item.uploadtime }}
                    </td>
                  </tr>
                </template>
                <template v-slot:expand="rowsprops">
                  <v-card>
                    <v-container
                      fill-height
                      fluid
                      pa-2>
                      <v-layout fill-height>
                        <v-flex
                          xs12
                          md2>
                          <v-card-text
                            width="100"
                            style="float: left">
                            <v-img
                              :src="rowsprops.item.img"
                              height="100"
                              width="100"
                              style="background: #eeeeee"
                              @click="
                                $previewImageLite.preview(rowsprops.item.img)
                              "
                            />
                          </v-card-text>
                        </v-flex>
                        <v-flex
                          xs12
                          md10>
                          <v-card-text
                            width="100"
                            style="float: left">
                            <span
                            >文件说明：<br >
                              {{ rowsprops.item.filedesc }}</span
                              >
                          </v-card-text>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card>
                </template>
              </v-data-table>
            </v-card>
            <v-btn
              color="primary"
              @click="selectGroup">
              选择设备组
            </v-btn>
            <v-btn
              color="primary"
              style="margin-left: 20px"
              @click="selectDevice"
            >
              选择设备
            </v-btn>
            <v-btn
              color="primary"
              flat
              style="margin-left: 40px"
              @click="backTo1"
            >
              上一步</v-btn
              >
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card-title>
              <v-flex
                xs12
                md3>
                <v-text-field
                  v-if="isGroup"
                  v-model="groupValue"
                  placeholder="已选择的设备组"
                  label="已选择的设备组"
                  class="v-date-range__input-field"
                  readonly
                  single-line
                  hide-details
                />
                <v-text-field
                  v-else
                  v-model="deviceValue"
                  placeholder="已选择的设备"
                  label="已选择的设备"
                  class="v-date-range__input-field"
                  readonly
                  single-line
                  hide-details
                />
              </v-flex>
              <v-flex
                xs12
                md6 />
              <v-flex
                xs12
                md3>
                <v-text-field
                  v-if="isGroup"
                  v-model="groupsearch"
                  append-icon="mdi-magnify"
                  label="智能模糊搜索"
                  single-line
                  hide-details
                />
                <v-text-field
                  v-else
                  v-model="devicesearch"
                  append-icon="mdi-magnify"
                  label="智能模糊搜索"
                  single-line
                  hide-details
                />
              </v-flex>
            </v-card-title>
            <v-card
              class="mb-5"
              color="#eeeeee">
              <v-data-table
                v-if="isGroup"
                :headers="groupheaders"
                :items="groupitems"
                :search="groupsearch"
                :pagination.sync="grouppagination"
                :rows-per-page-items="[grouppagination.rowsPerPage]"
                v-model="groupSelected"
                class="elevation-3"
              >
                <template v-slot:items="rowsprops">
                  <tr>
                    <td>
                      <v-checkbox
                        v-model="rowsprops.selected"
                        color="#42a5f5"
                        hide-details
                        @click="singleGroup(rowsprops)"
                      />
                    </td>
                    <td>{{ rowsprops.item.index }}</td>
                    <td>{{ rowsprops.item.groupname }}</td>
                    <td>{{ rowsprops.item.createtime }}</td>
                  </tr>
                </template>
              </v-data-table>
              <v-data-table
                v-else
                :headers="deviceheaders"
                :items="deviceitems"
                :search="devicesearch"
                :pagination.sync="devicepagination"
                :rows-per-page-items="[devicepagination.rowsPerPage]"
                v-model="deviceSelected"
                class="elevation-3 "
              >
                <template v-slot:items="rowsprops">
                  <tr>
                    <td>
                      <v-checkbox
                        v-model="rowsprops.selected"
                        color="#42a5f5"
                        hide-details
                        @click="singleDevice(rowsprops)"
                      />
                    </td>
                    <td>{{ rowsprops.item.index }}</td>
                    <td>{{ rowsprops.item.devicename }}</td>
                    <td>{{ rowsprops.item.serialcode }}</td>
                    <td>{{ rowsprops.item.createtime }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>

            <v-btn
              color="primary"
              @click="submit">
              确认发布
            </v-btn>

            <v-btn
              color="primary"
              flat
              style="margin-left: 40px"
              @click="backTo2"
            >
              上一步</v-btn
              >
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      e1: 0,
      isGroup: true,
      advertname: '新广告',
      today: '2019-10-01',
      rangeAdd: ['2019-10-01', '2019-10-01'],
      // start: '',
      // end: '',
      fileValue: '',
      fileheaders: [
        { sortable: false, text: '', value: 'filedesc', width: '3%' },
        { sortable: true, text: '#', value: 'index', width: '5%' },
        { sortable: true, text: '文件名', value: 'srcname', width: '55%' },
        { sortable: true, text: '文件大小', value: 'filesize', width: '15%' },
        { sortable: true, text: '上传日期', value: 'uploadtime', width: '20%' }
      ],
      fileitems: [],
      filesearch: '',
      filepagination: { page: 1, rowsPerPage: 5, totalItems: '', sortBy: '' },
      groupValue: '',
      groupheaders: [
        { sortable: false, text: '', value: '', width: '3%' },
        { sortable: true, text: '#', value: 'index', width: '10%' },
        { sortable: true, text: '设备组名称', value: 'roupname', width: '25%' },
        { sortable: true, text: '创建日期', value: 'createtime', width: '20%' }
      ],
      groupitems: [],
      groupsearch: '',
      grouppagination: { page: 1, rowsPerPage: 5, totalItems: '', sortBy: '' },
      deviceValue: '',
      deviceheaders: [
        { sortable: false, text: '', value: '', width: '3%' },
        { sortable: true, text: '#', value: 'index', width: '10%' },
        { sortable: true, text: '设备名称', value: 'devicename', width: '25%' },
        { sortable: true, text: '序列号', value: 'serialcode', width: '25%' },
        { sortable: true, text: '绑定日期', value: 'createtime', width: '20%' }
      ],
      deviceitems: [],
      devicesearch: '',
      devicepagination: { page: 1, rowsPerPage: 5, totalItems: '', sortBy: '' },
      fileselected: [],
      deviceSelected: [],
      groupSelected: [],
      rules: {
        required: value => !!value || '此项不能为空',
        counter: value => value.length <= 20 || '最多20个字符'
      }
    }
  },
  watch: {
    value (newVal, oldVal) {
      // console.log('value() ', newVal, oldVal)
      this.renew(newVal)
    }
  },
  created () {
    // console.log('today1: ', this.today)
  },
  mounted () {
    // console.log('today2: ', this.today)
  },
  methods: {
    renew (value) {
      // console.log('renew() ', value)
      if (value) {
        // console.log('today: ', this.today)
        let today = new Date().setHours(0, 0, 0, 0)
        let start =
          Math.round(new Date(today).getTime()) + 1000 * 60 * 60 * 24 * 1
        let end = start + 1000 * 60 * 60 * 24 * 7 - 1
        this.rangeAdd = [this.$getDateString(start), this.$getDateString(end)]
        this.today = this.$getDateString(today)
        this.e1 = 1
        // this.start = this.$getDateString(start)
        // this.end = this.$getDateString(end)
      }
    },
    complete (index) {
      this.list[index] = !this.list[index]
      // console.log('this.list[index]: ', index, this.list[index])
    },
    complete2 (index) {
      this.list2[index] = !this.list2[index]
      // console.log('this.list2[index]: ', index, this.list2[index])
    },
    dateAddInput (res) {
      // console.log('dateInput(): ', res)
      // console.log('this.range: ', this.rangeAdd)
      // console.log('this.start: ', this.start)
      // console.log('this.end: ', this.end)
    },
    submit () {
      let start = Math.round(new Date(this.rangeAdd[0] + ' 00:00:00').getTime()).toString()
      let end = Math.round(new Date(this.rangeAdd[1] + ' 23:59:59').getTime()).toString()
      // console.log('start: ', start)
      // console.log('end: ', end)
      // let formdata = new URLSearchParams()
      let formdata = new FormData()
      formdata.append('start', start)
      formdata.append('end', end)
      formdata.append('adName', this.advertname)
      formdata.append('fileId', this.fileselected[0].id)
      formdata.append('duration', this.fileselected[0].duration)
      // formdata.append('duration', '15')
      if (!this.$isEmpty(this.groupSelected[0])) {
        formdata.append('groupId', this.groupSelected[0].id)
      } else {
        formdata.append('deviceId', this.deviceSelected[0].id)
      }
      // console.log('this.fileselected[0]: ', this.fileselected[0])
      this.$http
        .post(this.$store.state.app.url + '/mediaSystem/advert/addAdvert', formdata, this.$authHeaders())
        .then(result => {
          let res = result.data
          if (res.code === 0) {
            this.fileselected = []
            this.deviceSelected = []
            this.groupSelected = []
            this.$emit('viewClosed', result.data.detail)
          } else {
            this.$snackbar.error(res.message)
          }
          // console.log('result.data: ', result.data)
        }, (error) => {
          this.$httpCatch(error)
        })
    },
    cancel () {
      this.$emit('viewClosed')
    },
    selectFile () {
      if (this.advertname === '') {
        return
      }
      this.e1 = 2
      this.fileitems = []
      this.$http
        .get(
          this.$store.state.app.url +
            '/mediaSystem/file/fileList?pageid=1&size=2000',
          this.$authHeaders()
        )
        .then(result => {
          let res = result.data
          if (res.code === 0) {
            for (let i = 0; i < res.detail.fileList.length; i++) {
              let obj = {
                id: res.detail.fileList[i].id,
                srcname: res.detail.fileList[i].srcName,
                duration: res.detail.fileList[i].duration,
                filesize: this.$formatFileSize(res.detail.fileList[i].fileSize),
                uploadtime: this.$getDateString(
                  res.detail.fileList[i].timeStamp
                ),
                filedesc: res.detail.fileList[i].fileDesc,
                index: i + 1,
                img: ''
              }
              this.fileitems.push(obj)
            }
            this.filepagination.totalItems = res.detail.fileList.length
          } else {
            console.error('result.data.code: ', result.data.code)
          }
        })
        .catch(error => {
          this.$httpCatch(error)
        })
    },
    showFileDetail (rowsprops) {
      if (rowsprops.expanded === true) {
        rowsprops.expanded = false
        return
      }
      rowsprops.expanded = !rowsprops.expanded
      if (!this.$isEmpty(rowsprops.item.img)) {
        return
      }
      let img = ''
      this.$http
        .get(
          this.$store.state.app.url +
            '/mediaSystem/file/getUrl?fileId=' +
            rowsprops.item.id,
          this.$authHeaders()
        )
        .then(result => {
          let imgRes = result.data
          if (imgRes.code === 0) {
            img = imgRes.detail.thumbnail
          } else {
            console.error('result.data.code: ', result.data.code)
          }
          rowsprops.item.img = img
        })
        .catch(error => {
          this.$httpCatch(error)
        })
    },
    selectGroup () {
      this.e1 = 3
      this.isGroup = true
      this.groupitems = []
      this.$http
        .get(
          this.$store.state.app.url +
            '/mediaSystem/group/groupList?pageid=1&size=2000',
          this.$authHeaders()
        )
        .then(result => {
          let res = result.data
          if (res.code === 0) {
            for (let i = 0; i < res.detail.list.length; i++) {
              let obj = {
                id: res.detail.list[i].id,
                groupname: res.detail.list[i].groupName,
                createtime: this.$getDateString(res.detail.list[i].timestamp),
                index: i + 1
              }
              this.groupitems.push(obj)
            }
            this.grouppagination.totalItems = res.detail.list.length
          } else {
            console.error('result.data.code: ', result.data.code)
          }
        })
        .catch(error => {
          this.$httpCatch(error)
        })
    },
    selectDevice () {
      this.e1 = 3
      this.isGroup = false
      this.deviceitems = []
      this.$http
        .get(
          this.$store.state.app.url +
            '/mediaSystem/device/deviceList?pageid=1&size=2000',
          this.$authHeaders()
        )
        .then(result => {
          let res = result.data
          if (res.code === 0) {
            for (let i = 0; i < res.detail.list.length; i++) {
              let obj = {
                id: res.detail.list[i].id,
                devicename: res.detail.list[i].description,
                createtime: this.$getDateString(res.detail.list[i].timestamp),
                serialcode: res.detail.list[i].serialCode,
                index: i + 1
              }
              this.deviceitems.push(obj)
            }
            this.devicepagination.totalItems = res.detail.list.length
          } else {
            console.error('result.data.code: ', result.data.code)
          }
        })
        .catch(error => {
          this.$httpCatch(error)
        })
    },
    singleFile (obj) {
      for (let i = 0; i < this.fileselected.length; i++) {
        this.fileselected.splice(i, 1)
        i--
        this.fileselected.selected = false
      }
      obj.selected = true
      this.fileselected.push(obj)
      this.fileValue = this.fileselected[0].srcname
    },
    singleGroup (obj) {
      for (let i = 0; i < this.groupSelected.length; i++) {
        this.groupSelected.splice(i, 1)
        i--
        this.groupSelected.selected = false
      }
      obj.selected = true
      this.groupSelected.push(obj)
      this.groupValue = this.groupSelected[0].groupname
    },
    singleDevice (obj) {
      for (let i = 0; i < this.deviceSelected.length; i++) {
        this.deviceSelected.splice(i, 1)
        i--
        this.deviceSelected.selected = false
      }
      obj.selected = true
      this.deviceSelected.push(obj)
      this.deviceValue = this.deviceSelected[0].devicename
    },
    backTo1 () {
      this.e1 = 1
      this.fileselected = []
      this.fileValue = ''
    },
    backTo2 () {
      this.e1 = 2
      this.groupSelected = []
      this.deviceSelected = []
      this.deviceValue = ''
      this.groupValue = ''
    }
  }
}
</script>
<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.dialog-mask {
  background-color: #eeeeee;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  display: table;
  animation-duration: 0s;
}
</style>
